<template>
  <div class="top-banner">
      
    <parallax sectionHeight="198px" imgHeight="auto" :speedFactor="0.25">
      <img :src="bannerURL" :alt="$cms.utils.getComputedLabel(labels.bannerAlt)">
    </parallax>

  </div>
</template>

<style>
  .top-banner {
    height:200px;
    background:transparent;
    position: relative;
    margin-left:50px;
    margin-right:50px;
    margin-top:10px;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 3px;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    z-index:10;
  }

  @media (max-width: 1199.98px) {
    .top-banner {
      margin-left:15px;
      margin-right:15px;
    }
  }

  @media (max-width: 575.98px) {
    .top-banner {
      margin:0px;
    }
  }

  .top-banner .Masthead {
    border-radius: 3px;
  }
  
</style>

<script lang="ts">
import { defineComponent, PropType } from '@fwk-node-modules/vue'
import { useCmsModule } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsBoolean, CmsText, CmsPicture, CmsList } from '@fwk-client/modules/cms/types';

/** @cmsOptions */
export interface TopBannerOptions {
  /** @cmsType CmsPicture */
  bannerURL?:CmsPicture
}

/** @cmsLabels */
export interface TopBannerLabels {
  bannerAlt?:string;
}

export default defineComponent({
    props: {
        options: Object as PropType<TopBannerOptions>,
        labels: {
          type: Object as PropType<TopBannerLabels>,
          default: () => { return {} }
        }
    },
    setup(props, context) {
        const cms = useCmsModule();

        const computedOptions:TopBannerOptions = {
          ...props.options,
          bannerURL : (props.options && props.options.bannerURL) ? cms.utils.getComputedPictureURL(props.options.bannerURL) : undefined
        };

        return {
            bannerURL: computedOptions.bannerURL,
            labels: props.labels
        }

    }
})
</script>